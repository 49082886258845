<template>
  <section class="section bg-gray" id="contact-us">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 title-box text-center mb-5">
          <h2 class="text-center" style="font-size: 3rem;">
            {{$t("Drop us a line or two")}}
          </h2>
          <h3>{{$t("We'd love to hear from you")}}</h3>
          <div class="border"></div>
        </div>
        <div class="col-lg-6">
          <form
            role="form"
            name="contactus-form"
            id="ajax-form"
            @submit="checkForm"
            action="https://formspree.io/j@kurlabs.com"
            method="POST"
            class="form-main"
          >
          <p v-if="errors.length" class="text-center row">
            
            <ul class="text-center col-12">
              <li><b class="text-center">{{$t("Please correct the following error (s):")}}</b></li>
              <li v-for="error in errors" class="text-center text-orange" :key="error">
                {{ $t(error) }}
              </li>
            </ul>
          </p>
            <div class="form-group">
              <label for="name2" class="font-weight-bold">{{$t("Name")}}</label>
              <input
                class="form-control"
                id="name2"
                v-model="name"
                name="name-name"
                type="text"
                value="Name"
              />
            </div>

            <div class="form-group">
              <label for="email2" class="font-weight-bold">{{$t("Email")}}</label>
              <input
                class="form-control"
                id="email2"
                v-model="replyto"
                name="_replyto"
                type="text"
                value="E-mail"
              />
            </div>

            <div class="form-group">
              <label for="message2" class="font-weight-bold">{{$t("Message")}}</label>
              <textarea
                class="form-control"
                id="message2"
                v-model="text"
                name="name-text"
                rows="4"
              >
</textarea
              >

              <div class="error" id="err-message" style="display: none;">
                {{$t("Please enter message")}}
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <button
                  type="submit"
                  class="btn btn-primary btn-shadow btn-rounded w-md"
                  id="send"
                >
                  {{$t("Submit")}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactUs",
  data: function() {
    return {
      errors: [],
      name: null,
      replyto: null,
      text: null
    };
  },
  methods: {
    checkForm: function(e) {
      if (this.replyto && this.text) {
        return true;
      }

      this.errors = [];

      if (!this.replyto) {
        let error_email ="Email is required.";
        this.errors.push(error_email);
      }
      if (!this.age) {
        let error_msg = "The message is required.";
        this.errors.push(error_msg);
      }

      e.preventDefault();
    }
  }
};
</script>
<style scoped>
  li{
    list-style-type:none;
  }
  .text-orange{
    color:#f1633d!important;
  }
</style>
